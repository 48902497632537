import { ProcessingIndicator } from '@/src/modules/resource-detail/components/ExpandedResource/components/ProcessingIndicator';
import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { Fdoc } from '@/src/types/api';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const HeaderWrapperBase = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(env(safe-area-inset-top) + 4rem);
  padding: 0 1rem;
  padding-top: calc(env(safe-area-inset-top));
  border-bottom: 1px solid ${cssVar['color-border-primary']};
  gap: 1rem;
  position: relative;
  z-index: 40;
  background: ${cssVar['color-bg-primary']};
  flex-shrink: 0;
`;

const Left = styled(Flex)
  .attrs<{ resource: Fdoc | null }>(({ children, resource, ...rest }) => {
    return {
      alignItems: 'center',
      overflow: 'hidden',
      ...rest,
      children: (
        <>
          {resource && <ProcessingIndicator resource={resource} />}
          {children}
        </>
      ),
    };
  })
  .withConfig(preventForwardPropsConfig(['resource']))`
  gap: 0.75rem;
  flex-grow: 1;
  width: 1px;
  height: 100%;

  /** override old components paddings */
  & > * {
    padding-left: 0;
    margin-left: 0;
  }
`;

const Right = styled(Flex).attrs(({ children, ...rest }) => {
  return {
    alignItems: 'center',
    overflow: 'hidden',
    ...rest,
    children: (
      <>
        {children}
        <Modal.Close variant="bg-secondary" size="sm" data-testid="close-preview-button" />
      </>
    ),
  };
})`
  gap: 0.75rem;
  /** override old components paddings */
  & > * {
    margin-left: 0;
  }
`;

export const HeaderWrapper = Object.assign(HeaderWrapperBase, {
  Left,
  Right,
});
