import { useCustomRouter } from '@/src/lib/next-router/useCustomRouter';
import { pick } from '@/src/lib/store';
import { ResourceViewMobile } from '@/src/modules/resource-detail/components/ExpandedResource/MobileView/ResourceViewMobile';
import Modal, { defaultModalContentProps } from '@/src/modules/ui/components/Modal';
import {
  UsePanningHandler,
  swipeConfidenceThreshold,
  swipePower,
  usePanning,
} from '@/src/modules/ui/hooks/usePanning';
import { cssVar } from '@/src/modules/ui/theme/variables';
import useFdocSwitcher from '@/src/store/fdocSwitcher';
import useUIStore from '@/src/store/ui';
import { DialogContent, DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { motion, useWillChange } from 'framer-motion';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { shallow } from 'zustand/shallow';

type ItemOrder = 'prev' | 'current' | 'next';

const inAnimation = keyframes`
  from {
    /* scale: 0.8; */
    opacity: 0;
  }
  to {
    /* scale: 1; */
    opacity: 1;
  }
`;

const outAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface ItemWrapperProps {
  order: ItemOrder;
}

const ItemWrapper = styled(motion.div)<ItemWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const Item = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 100;
  left: 0;
  top: 0;
  box-shadow: 0 0 20px 0 rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.1);
`;

const itemWrapperVariants = {
  prev: {
    x: '-100%',
    zIndex: 5,
  },
  next: {
    x: '100%',
    zIndex: 6,
  },
  current: {
    x: 0,
    zIndex: 10,
  },
};

const innerInitial = {
  x: 0,
};

const itemTransition = {
  x: {
    type: 'easeInOut',
    duration: 0.2,
    delay: 0,
  },
};

const ModalDialog = styled(DialogContent)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 125;
  transition: none;
  overscroll-behavior-x: contain;

  &[data-state='open'] {
    animation: ${inAnimation} 0.2s ease-out forwards;
  }

  &[data-state='closed'] {
    animation: ${outAnimation} 0.2s ease-out forwards;
  }
`;

/**
 * uiStoreExpandedResourceId is the source of truth of currently expanded resource
 */
export const ModalResource = () => {
  const {
    expandedFdocId: uiStoreExpandedResourceId,
    setExpandedFdocId: setUiStoreExpandedResourceId,
  } = useUIStore((state) => pick(state, ['expandedFdocId', 'setExpandedFdocId']), shallow);

  const willChange = useWillChange();
  const router = useCustomRouter();
  const { replaceSearchParams, getStringQueryParams } = router;

  const {
    switcherFdocIds,
    goToNextFdoc,
    hasNext,
    goToPreviousFdoc,
    hasPrev,
    setFdocId: setSwitcherResourceId,
  } = useFdocSwitcher(
    (s) =>
      pick(s, [
        'setFdocId',
        'hasPrev',
        'hasNext',
        'fdocId',
        'switcherFdocIds',
        'goToNextFdoc',
        'goToPreviousFdoc',
      ]),
    shallow,
  );

  const [expandedResourceIdFromRouter] = getStringQueryParams(['expandedFdocId']);

  /**
   * open coresponding resource id from url on page load
   * and set correspoding internal states
   */
  React.useEffect(() => {
    if (expandedResourceIdFromRouter === uiStoreExpandedResourceId) return;
    if (expandedResourceIdFromRouter) {
      setUiStoreExpandedResourceId(expandedResourceIdFromRouter);
      setSwitcherResourceId(expandedResourceIdFromRouter);
    } else {
      setUiStoreExpandedResourceId(null);
    }

    /**
     * INTENTIONAL
     * This should run only on mount
     * otherwise please use setExpandedFdocId and setFdocId programatically, not as side effect
     * otherwise this causes state rewrites etc.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * we have some hybrid thing going on here, having the id in both uistore and useFdocSwitcher, we need to sync
   * consider uiStoreExpandedResourceId as a source of truth
   */
  React.useEffect(() => {
    if (uiStoreExpandedResourceId) {
      setSwitcherResourceId(uiStoreExpandedResourceId);
    }
  }, [setSwitcherResourceId, uiStoreExpandedResourceId]);

  const resourcesToRender = React.useMemo(() => {
    if (!uiStoreExpandedResourceId) {
      return [];
    }

    const resourceIndex = switcherFdocIds.findIndex((item) => item === uiStoreExpandedResourceId);
    return [
      {
        id: switcherFdocIds[resourceIndex - 1],
        order: 'prev' as const,
      },
      {
        id: uiStoreExpandedResourceId,
        order: 'current' as const,
      },
      {
        id: switcherFdocIds[resourceIndex + 1],
        order: 'next' as const,
      },
    ].filter((item) => item.id);
  }, [switcherFdocIds, uiStoreExpandedResourceId]);

  /**
   * some styles depends on these values
   * e.g. swiping between items, suddenly it can happen theres no next
   * resource and different styles are applied
   *
   * Deferring so the animations finish before the different styles are applied
   */
  const hasNextResource = React.useDeferredValue(hasNext());
  const hasPrevResource = React.useDeferredValue(hasPrev());

  /**
   * panning
   */

  const onPanEnd: UsePanningHandler = React.useCallback(
    (e, { offset, velocity }, { axis }) => {
      /**
       * panning on X axis - swiping between items
       */
      if (axis === 'x') {
        const swipe = swipePower(offset.x, velocity.x);
        if (swipe < -swipeConfidenceThreshold && hasNextResource) {
          const nextResourceId = goToNextFdoc();
          setUiStoreExpandedResourceId(nextResourceId, undefined);
        } else if (swipe > swipeConfidenceThreshold && hasPrevResource) {
          const nextResourceId = goToPreviousFdoc();
          setUiStoreExpandedResourceId(nextResourceId, undefined);
        }
      }
    },
    [
      hasNextResource,
      hasPrevResource,
      setUiStoreExpandedResourceId,
      goToNextFdoc,
      goToPreviousFdoc,
    ],
  );

  const panning = usePanning({
    onPanEnd,
    panningLayerId: 'expanded-resource',
  });

  /**
   * deleting
   */
  const handleOnDelete = React.useCallback(() => {
    if (switcherFdocIds.length > 1) {
      goToNextFdoc();
    } else {
      setUiStoreExpandedResourceId(null);
    }
  }, [switcherFdocIds.length, goToNextFdoc, setUiStoreExpandedResourceId]);

  return (
    <Modal
      open={resourcesToRender.length > 0 && uiStoreExpandedResourceId !== null}
      onOpenChange={(v) => {
        if (!v) {
          setUiStoreExpandedResourceId(null);
          replaceSearchParams({ expandedFdocId: undefined }, undefined, { shallow: true });
        }
      }}
    >
      <Modal.Portal>
        <Modal.Overlay visibleOnMobileViewport noMobileBlur />
        <ModalDialog {...defaultModalContentProps}>
          <DialogTitle style={{ display: 'none' }}>Resource detail</DialogTitle>
          <DialogDescription style={{ display: 'none' }}>
            Shows details of the selected resource
          </DialogDescription>
          <motion.div
            style={{ width: '100vw', height: '100vh', position: 'relative' }}
            onPanStart={panning.motionProps.onPanStart}
            onPan={panning.motionProps.onPan}
            onPanEnd={panning.motionProps.onPanEnd}
          >
            {resourcesToRender.map(({ id, order }) => {
              const variants = {
                panning: {
                  // We always clamp the value because sometimes it springs outside of bounds and it
                  // feels broken, because depending on the device the spring animation is a bit choppy
                  x: !hasNextResource
                    ? Math.min(Math.max(panning.panningXOffset, 0), window.innerWidth)
                    : !hasPrevResource
                      ? Math.max(Math.min(panning.panningXOffset, 0), -window.innerWidth)
                      : Math.max(
                          Math.min(panning.panningXOffset, window.innerWidth),
                          -window.innerWidth,
                        ),
                  transition: {
                    type: false,
                  },
                },
                current: {
                  x: 0,
                  y: 0,
                  transition: {
                    type: 'easeInOut',
                    // could probably make it even better by calculating the duration based on pan ammount
                    duration: 0.15,
                  },
                },
              };

              /**
               * when panning on Y axis, we detect swipe down to close
               * the animation is enabled only on an active item in the view
               * !! disabled for the time being
               */
              const _isPanningY = order === 'current' && panning.isPanningY;

              /**
               * when panning on X axis, we detect swipe left/right
               * when going left, we animate only current and right item and vice versa
               */
              const isPanningX =
                panning.isPanningX &&
                ((order === 'prev' && panning.panningXOffset > 0) ||
                  (order === 'next' && panning.panningXOffset < 0) ||
                  order === 'current');

              return (
                <ItemWrapper
                  key={id}
                  layoutId={id}
                  transition={itemTransition}
                  order={order}
                  initial={order}
                  animate={order}
                  variants={itemWrapperVariants}
                >
                  <Item
                    initial={innerInitial}
                    animate={isPanningX ? 'panning' : 'current'}
                    variants={variants}
                    style={{
                      willChange,
                    }}
                  >
                    <ResourceViewMobile
                      handleOnDelete={handleOnDelete}
                      resourceId={id}
                      isVisible={order === 'current'}
                    />
                  </Item>
                </ItemWrapper>
              );
            })}
          </motion.div>
        </ModalDialog>
      </Modal.Portal>
    </Modal>
  );
};
