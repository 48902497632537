import { useAuthUser } from '@/src/hooks/auth';
import { useInputControls } from '@/src/hooks/useInputControls';
import { ResourceTitleEditable } from '@/src/modules/resource-detail/components/ResourceTitle/ResourceTitleEditable';
import { useMutationResourceTitleRename } from '@/src/modules/resource-detail/mutations/useMutationResourceTitleRename';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import { isNotepadFdoc, isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

const NewModalWrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  & > * {
    margin-left: 0;
  }
`;

export const ExpandedResourceTitle: React.FC<{ resource: OptimisticDraft<Fdoc> }> = ({
  resource,
}) => {
  const resourceTitle = getResourceTitleWithDefault(resource);

  const { mutate: mutateResourceTitleRename } = useMutationResourceTitleRename();

  const user = useAuthUser();

  const isTitleEditable = isStoredFileFdoc(resource) || isNotepadFdoc(resource);

  const titleInput = useInputControls(resourceTitle, {
    onChange: (value: string) => {
      if (resource.isDraft) {
        /**
         * @TODO
         * currently on mobile this doesn't support draft
         */
        // setNeedsSaving(true)
        return;
      }
      if (isTitleEditable && resourceTitle !== value) {
        mutateResourceTitleRename({
          resource,
          newTitle: value,
        });
      }
    },
    debounce: 500,
  });

  return (
    <NewModalWrapper>
      <ResourceTitleEditable
        titleInput={titleInput}
        setIsEditorFocused={() => {
          /**
           * @TODO
           */
        }}
        readOnly={!isTitleEditable}
        resource={resource}
        user={user}
      />
    </NewModalWrapper>
  );
};
