import MessageForm from '@/src/components/Chatbot/MessageForm';
import MessagesList from '@/src/components/Chatbot/MessageList';
import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { MessagesProvider } from '@/src/hooks/chatbot';
import * as AssistantHeader from '@/src/modules/assistant/components/AssistantHeader';
import { AssistantQuickActions } from '@/src/modules/assistant/components/AssistantQuickActions';
import { useSidebarScrollbarFadeColor } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { Fdoc } from '@/src/types/api';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

export const ExtraPanelTabAssistant: React.FC<{ resource: Fdoc; commentInputVisible: boolean }> = ({
  commentInputVisible,
}) => {
  const fadeColor = useSidebarScrollbarFadeColor();
  const { sidebarAnimateIn } = useExpandedFdocContext();
  const isInputVisible = commentInputVisible;

  return (
    <MessagesProvider chatAssistantOpen={true}>
      <ForcedTheme colorScheme="dark" asChild>
        <Flex
          direction="column"
          style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        >
          <AssistantHeader.Root>
            <AssistantHeader.Left>
              <P size="xs">Asking about:</P>
              <AssistantHeader.ContextSelector />
            </AssistantHeader.Left>
            <AssistantHeader.ResetButton />
          </AssistantHeader.Root>
          <MessagesList fadeColor={fadeColor} />
          <AssistantQuickActions />
        </Flex>
      </ForcedTheme>
      <AnimatePresence initial={sidebarAnimateIn}>
        {isInputVisible && <MessageForm />}
      </AnimatePresence>
    </MessagesProvider>
  );
};
